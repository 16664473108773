// src/components/Header.js
import React from "react";
import './Header.css';

function Header() {
    return (
        <header className="header">
            <h1>Notre Dame Studio</h1>
        </header>
    );
}

export default Header;