// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/Header.css */
.header{
    background-color: #77B8E3;
    display: flex;
    justify-content: center;
    align-content: center;
    height: 150px;
}

.header h1{
    color: white;
    font-size: 3rem;
    margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/Header.css"],"names":[],"mappings":"AAAA,8BAA8B;AAC9B;IACI,yBAAyB;IACzB,aAAa;IACb,uBAAuB;IACvB,qBAAqB;IACrB,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,eAAe;IACf,SAAS;AACb","sourcesContent":["/* src/components/Header.css */\n.header{\n    background-color: #77B8E3;\n    display: flex;\n    justify-content: center;\n    align-content: center;\n    height: 150px;\n}\n\n.header h1{\n    color: white;\n    font-size: 3rem;\n    margin: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
