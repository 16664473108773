// src/pages/Home.css
import React from "react";
import Header from "../components/Header";

function Home(){
    return (
        <div>
            <Header /> {}
        </div>
    )
}

export default Home;